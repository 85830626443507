/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull accounts-website'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 10
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/dixa/Dixa%20Accounts/implementation/accounts-website)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'prod' | 'stag';

export const ApiKey: Record<Environment, string> = {
  prod: '1380634f01ab62dad21a1c38cd34c338',
  stag: '758fc376fe1955f71d8fbf88f0f527c4'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '10',
    branch: 'main',
    source: 'accounts-website',
    versionId: '8cefee9c-5540-466b-b62e-83627c47c33f'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  initial_referrer?: any;
  referrer?: any;
}

export interface LinkToEmailProviderClickedProperties {
  provider: string;
}

export interface SigninMagiclinkFormSubmittedProperties {
  /**
   * The email thats signing in.
   */
  email: string;
}

export interface SignupSucceededProperties {
  /**
   * The name of the company that has signed up
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  company_name: string;
  /**
   * The domain claimed
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  domain: string;
  /**
   * The email of the person whom signed up
   */
  email: string;
  /**
   * The name of the person whom signed up
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  full_name: string;
  /**
   * If the person has accepted marketing
   */
  has_accepted_marketing: boolean;
  /**
   * The phone number for the person whom signed up
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  phone_number: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkToEmailProviderClicked implements BaseEvent {
  event_type = 'Link To Email Provider Clicked';

  constructor(
    public event_properties: LinkToEmailProviderClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SigninMagiclinkFormSubmitted implements BaseEvent {
  event_type = 'Signin Magiclink Form Submitted';

  constructor(
    public event_properties: SigninMagiclinkFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SigninWithAnotherEmailClicked implements BaseEvent {
  event_type = 'Signin With Another Email Clicked';
}

export class SigninWithSsoClicked implements BaseEvent {
  event_type = 'Signin With Sso Clicked';
}

export class SigninWithSsoSucceeded implements BaseEvent {
  event_type = 'Signin With Sso Succeeded';
}

export class SignupSucceeded implements BaseEvent {
  event_type = 'Signup Succeeded';

  constructor(
    public event_properties: SignupSucceededProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Link To Email Provider Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Link%20To%20Email%20Provider%20Clicked)
   *
   * When the user clicks a link that quickly takes them to their email provider. eg. Gmail
   *
   * Owner: Domantas L.
   *
   * @param properties The event's properties (e.g. provider)
   * @param options Amplitude event options.
   */
  linkToEmailProviderClicked(
    properties: LinkToEmailProviderClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkToEmailProviderClicked(properties), options);
  }

  /**
   * Signin Magiclink Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Signin%20Magiclink%20Form%20Submitted)
   *
   * When the user submits the sign in form
   *
   * Owner: Domantas L.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  signinMagiclinkFormSubmitted(
    properties: SigninMagiclinkFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SigninMagiclinkFormSubmitted(properties), options);
  }

  /**
   * Signin With Another Email Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Signin%20With%20Another%20Email%20Clicked)
   *
   * When the user clicks the links that navigates them back to the signin page, likely because they had a mistake in their email
   *
   * @param options Amplitude event options.
   */
  signinWithAnotherEmailClicked(
    options?: EventOptions,
  ) {
    return this.track(new SigninWithAnotherEmailClicked(), options);
  }

  /**
   * Signin With Sso Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Signin%20With%20Sso%20Clicked)
   *
   * When the user clicks Sign in with SSO button
   *
   * @param options Amplitude event options.
   */
  signinWithSsoClicked(
    options?: EventOptions,
  ) {
    return this.track(new SigninWithSsoClicked(), options);
  }

  /**
   * Signin With Sso Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Signin%20With%20Sso%20Succeeded)
   *
   * When SSO sign in was successful
   *
   * @param options Amplitude event options.
   */
  signinWithSsoSucceeded(
    options?: EventOptions,
  ) {
    return this.track(new SigninWithSsoSucceeded(), options);
  }

  /**
   * Signup Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/dixa/Dixa%20Accounts/events/main/latest/Signup%20Succeeded)
   *
   * When a person signs up to a trial accounts
   *
   * @param properties The event's properties (e.g. company_name)
   * @param options Amplitude event options.
   */
  signupSucceeded(
    properties: SignupSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupSucceeded(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
